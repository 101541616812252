import request from '@/service/request'

import store from '@/store/index'

const _permission = store.getters['login/getPermission']
const isAdmin = store.state.login.isAdmin
/* 羽毛球预约记录，分页
* 接口地址准确 因为后端颠颠的，乱来 ，导致羽毛球几个page接口命名不规范
* @params
* */
export const pageBadmintonOrderRecordApi = (params) => {
  // 管理员查看所有数据
  // 否则按管辖球场获取数据
  const url = isAdmin || _permission.includes('badminton:court:edit') ? '/stu/badmintonStuOrderRecord/page' : '/stu/badmintonStuOrderRecord/pageCurrentTeacher'
  return request.post(url, params)
}

/* 羽毛球预约记录，list
* @params
* */
export const listBadmintonOrderRecordApi = (params = {}) => {
  return request.post('/stu/badmintonStuOrderRecord/list', params)
}

/* 羽毛球预约记录，根据id获取
* @params
* */
export const getBadmintonOrderRecordByIdApi = (id) => {
  return request.post(`/stu/badmintonStuOrderRecord/get/${id}`)
}
/* 羽毛球预约记录，删除
* @params
* */
export const delBadmintonOrderRecordByIdApi = (id) => {
  return request.post(`/stu/badmintonStuOrderRecord/del/${id}`)
}

/* 羽毛球预约记录，保存
* @params
* */
export const saveBadmintonOrderRecordApi = (params) => {
  return request.post('/stu/badmintonStuOrderRecord/save', params)
}

/* 羽毛球预约记录，取消预约
* @params
* */
export const cancelBadmintonOrderRecordApi = (fixedCode) => {
  return request.post('/stu/badmintonStuOrderRecord/cancel', { fixedCode: fixedCode })
}
